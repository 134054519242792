import moment from 'moment'


jQuery(document).ready(function ($) {

  //COUNTDOWN
//COUNTDOWN
const countdownElement = $(".countdown");
const countDownDate = moment('2024-03-30 20:00+02:00', "YYYY-MM-DD HH:mmZZ");

// Update the countdown every second
let x = setInterval(function () {
  const now = moment();
  const diffSeconds = countDownDate.diff(now, 'seconds');
  
  // Calculate days, hours, minutes, and seconds
  const days = Math.floor(diffSeconds / (3600 * 24));
  const hours = Math.floor((diffSeconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((diffSeconds % 3600) / 60);
  const seconds = diffSeconds % 60;

  // Determine if we need to display days or not
  if (days > 0) {
    countdownElement.html(`
      <span class="days10" style="margin-right: -3px; letter-spacing: 3px;">${Math.floor(days / 10)}</span>
      <span class="days1">${days % 10}</span>
      :
      <span class="hours10">${Math.floor(hours / 10)}</span>
      <span class="hours1">${hours % 10}</span>
      :
      <span class="minutes10">${Math.floor(minutes / 10)}</span>
      <span class="minutes1">${minutes % 10}</span>
      :
      <span class="seconds10">${Math.floor(seconds / 10)}</span>
      <span class="seconds1">${seconds % 10}</span>
    `);
  } else {
    countdownElement.html(`
      <span class="hours10" style="margin-right: -3px; letter-spacing: 3px;">${Math.floor(hours / 10)}</span>
      <span class="hours1">${hours % 10}</span>
      :
      <span class="minutes10">${Math.floor(minutes / 10)}</span>
      <span class="minutes1">${minutes % 10}</span>
      :
      <span class="seconds10">${Math.floor(seconds / 10)}</span>
      <span class="seconds1">${seconds % 10}</span>
    `);
  }

  if (diffSeconds < 0) {
    clearInterval(x);
    countdownElement.hide();
    $(".preorder").html(`<img src="./dist/img/watch.png" alt="Watch">`);
    $(".coming-soon").hide()
  }
}, 1000);



  // ANIMATIONS --------------------

  // IMAGE CYCLING
  const rightheader = [
    './dist/img/right_header/live-forever-0.png',
    './dist/img/right_header/live-forever-1.png',
    './dist/img/right_header/live-forever-2.png',
    './dist/img/right_header/live-forever-3.png',
  ]
  const left = [
    './dist/img/left/live-forever-0.png',
    './dist/img/left/live-forever-1.png',
    './dist/img/left/live-forever-2.png',
    './dist/img/left/live-forever-3.png',
  ]
  const bottom = [
    './dist/img/bottom/live-forever-0.png',
    './dist/img/bottom/live-forever-1.png',
    './dist/img/bottom/live-forever-2.png',
    './dist/img/bottom/live-forever-3.png',
  ]
  const colorBlocks = [
    './dist/img/color_blocks/color-blocks-0.png',
    './dist/img/color_blocks/color-blocks-1.png',
    './dist/img/color_blocks/color-blocks-2.png',
    './dist/img/color_blocks/color-blocks-3.png',
  ]

  // Function to cycle images for a specified image element
  function cycleImages(selector, imagesArray) {
    setInterval(function () {
      const randomIndex = Math.floor(Math.random() * imagesArray.length);
      const randomImage = imagesArray[randomIndex];
      $(selector).attr('src', randomImage);
    }, Math.random() * 1000); // Random interval less than a second
  }

  // cycleImages('#right-header', rightheader);
  // cycleImages('#left', left);
  // cycleImages('#bottom', bottom);
  // cycleImages('#color-blocks-header', colorBlocks);
  

  // SCROLLING ANIMATIONS

  // Function to flicker elements when they come into view
  function flickerOnScroll() {
    $('.fade-in-scrollview').each(function () {
      if (elementInView(this)) {
        flickerElement($(this));
      }
    });
  }

  // ----------------------------------------------

  // FLICKER ON SCROLL VIEW
  // FLICKER ELEMENTS ON VIEW
  function flickerOnScroll() {
    $('.flicker-scrollview').each(function () {
      if (elementInView(this)) {
        flickerElement($(this));
      }
    });
  }

  // FLICKER ELEMENT FOR A SPECIFIC DURATION
  function flickerElement(element) {
    let flickerDuration = 1000;
    let startTime = Date.now();

    function flickerEffect() {
      element.animate({ opacity: Math.random() }, 50); // Set a random opacity value every 50ms
    }

    let flickerTimer = setInterval(flickerEffect, 50); // Flicker every 50ms

    setTimeout(() => {
      clearInterval(flickerTimer); // Stop flickering after the specified duration
      element.css('opacity', '1'); // Ensure the element is visible after flickering
    }, flickerDuration);
  }

  // ----------------------------------------------

  // ADJUST SIZE BASED ON SCROLL POSITION OF ANY ELEMENT
  function adjustElementSizeBasedOnScroll(startingWidth, finalWidth) {
    $(window).scroll(function () {
      var scrollPosition = $(this).scrollTop();
      var windowHeight = $(this).height();
      var docHeight = $(document).height();
      var totalScrollableDistance = docHeight - windowHeight;
      var scrollFraction = scrollPosition / totalScrollableDistance;

      // Adjust the scrollFraction to start scaling at 80%
      scrollFraction = Math.max(0, (scrollFraction - 0.8) / 0.2);

      var widthPercentage = (scrollFraction * 20) + 100;
      var finalWidthValue = startingWidth + ((widthPercentage / 100) * (finalWidth - startingWidth));

      $('.dynamic-element').css('width', `${finalWidthValue}%`);
    });
  }

  var startingWidthEle = 90;
  var finalWidthEle = 120;

  adjustElementSizeBasedOnScroll(startingWidthEle, finalWidthEle);

  // ----------------------------------------------

  // ADJUST SIZE OF BG IMAGE BASED ON SCROLL POSITION
  function adjustBackgroundSizeBasedOnScroll(startingWidth, finalWidth) {
    $(window).scroll(function () {
      var scrollPosition = $(window).scrollTop();
      var windowHeight = $(window).height();
      var docHeight = $(document).height();
      var totalScrollableDistance = docHeight - windowHeight;
      var scrollFraction = scrollPosition / totalScrollableDistance;

      var widthPercentage;
      if (scrollFraction <= 0.5) {
        widthPercentage = scrollFraction * 2 * 100;
      } else {
        widthPercentage = (1 - scrollFraction) * 2 * 100;
      }

      var finalWidthValue = startingWidth + ((widthPercentage / 100) * (finalWidth - startingWidth));

      $('.dynamic-bg-image').css('background-size', `${finalWidthValue}% auto`);
    });
  }

  // % of width
  var startingWidthBg = 50;
  var finalWidthBg = 68;

  adjustBackgroundSizeBasedOnScroll(startingWidthBg, finalWidthBg);

  // ----------------------------------------------

  // SHOW ON SCROLL
  function setScrollAnimation(elementSelector, startPercent, stopPercent) {
    $(window).on('scroll', function () {
      var documentHeight = $(document).height();
      var windowHeight = $(window).height();
      var scrollPosition = $(window).scrollTop();
      var scrollPercent = (scrollPosition / (documentHeight - windowHeight)) * 100;

      if (scrollPercent > startPercent && scrollPercent < stopPercent) {
        $(elementSelector).css('opacity', 1);
      } else {
        $(elementSelector).css('opacity', 0);
      }
    });
  }

  // Example usage:
  // This will apply the animation to the element with the ID 'exampleElement'
  // making it fully visible between the scroll percentage of 10% and 50%
  
  // setScrollAnimation('#element-to-animate', 40, 50);

  // ----------------------------------------------

  // FLICKER ANIMATION
  function randomIntervalFlicker(element) {
    const flicker = () => {
      if (Math.random() > 0.5) {
        $(element).animate({ opacity: '0' }, 50).animate({ opacity: '1' }, 50);
      }
      setTimeout(flicker, Math.floor(Math.random() * 2000) + 500);
    };

    flicker(); // Initial call to start flickering
  }

  // Usage
  $('.flicker').each(function () {
    randomIntervalFlicker(this);
  });

  // ----------------------------------------------

  // FAST FLICKER ANIMATION
  function fastRandomIntervalFlicker(element) {
    const flicker = () => {
      if (Math.random() > 0.5) {
        $(element).animate({ opacity: '0' }, 25).animate({ opacity: '1' }, 25);
      }
      setTimeout(flicker, Math.floor(Math.random() * 1000) + 250); // Adjusted interval for faster flickering
    };

    flicker(); // Initial call to start flickering
  }

  // Usage
  $('.fast-flicker').each(function () {
    fastRandomIntervalFlicker(this);
  });

  // ----------------------------------------------

  // QUICK FLICKER ANIMATION
  function quickFlicker(element) {
    const flicker = () => {
      $(element).animate({ opacity: '0' }, {
        duration: 0,
        step: function (now, fx) {
          $(this).stop().css({ opacity: now });
        }
      }).delay(Math.floor(Math.random() * 200) + 50).animate({ opacity: '1' }, {
        duration: 0,
        step: function (now, fx) {
          $(this).stop().css({ opacity: now });
        }
      });
      setTimeout(flicker, Math.floor(Math.random() * 300) + 100); // Random interval between 100ms to 400ms
    };

    flicker(); // Initial call to start flickering
  }

  // Usage
  $('.quick-flicker').each(function () {
    quickFlicker(this);
  });

});